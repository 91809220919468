<template>
  <section>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
          style="gap: 8px;"
        >
          <div>
            <h4>{{ $t('Danh sách phiếu kiểm kho') }}</h4>
            <!-- <div>{{ $t('Create combos with attractive offers that will attract more buyers!') }}</div> -->
          </div>
          <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="add"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('Create new') }}
          </b-button>
        </div>

        <!-- <div class="filter-tab w-100">
          <div
            v-for="item in filterTabs"
            :key="item.id"
            :class="['filter-tab__item', {'active': filterSelected == item.id}]"
            @click="filterSelected = item.id"
          >
            {{ $t(item.label) }}
          </div>
        </div> -->
      </b-card-header>
      <b-card-body>
        <!-- search input -->
        <div class="custom-search d-flex justify-content">
          <b-form-group style="width: 450px;">
            <div class="d-flex align-items-center mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Tìm kiếm theo mã kiểm kho"
                />
              </b-input-group>
            </div>
          </b-form-group>
          <!-- search theo danh mục -->
          <b-form-group style="width: 350px;">
            <div class="d-flex align-items-center mr-1">
              <!-- <label class="mr-1">{{ $t('Discount promotion period') }}</label> -->
              <treeselect
                v-model="model.supplier_id"
                :multiple="false"
                :clearable="true"
                :searchable="true"
                :open-on-click="true"
                :clear-on-select="true"
                :show-count="true"
                :options="supplierOptions"
                :limit="5"
                :max-height="400"
                :placeholder="$t('Nhà kho')"
                @input="handlerChangeCategory"
              />
            </div>
          </b-form-group>
          <!-- <b-form-group style="width: 350px;">
            <div class="d-flex align-items-center mr-1">
              <flat-pickr
                v-model="model.date"
                class="form-control"
                :config="dateRangeConfig"
                :placeholder="$t('From - To date')"
              />
            </div>
          </b-form-group> -->
        </div>

        <!-- table -->
        <vue-good-table
          ref="inventory-receives"
          class="custom-good-table v2"
          :columns="fields"
          :rows="filterItems"
          style-class="vgt-table"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Avatar + Name -->
            <span
              v-if="props.column.field === 'code'"
              class="d-flex align-items-center"
            >
              <router-link :to="`edit/${props.row.id}`">{{ props.row.code }}</router-link>
              <feather-icon
                v-b-tooltip.hover.top="'Lý do trong chi tiết show ở đây'"
                icon="FileTextIcon"
              />
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                class=""
                :variant="checkStatus[`s${props.row.status}`].color"
              >
                {{ checkStatus[`s${props.row.status}`].text }}
              </b-badge>
            </span>

            <!-- Column: created at -->
            <span v-else-if="props.column.field === 'created_at'">
              {{ props.row.created_at }}
              <div>
                <feather-icon icon="UserIcon" />
                <small>{{ props.row.user }}</small>
              </div>
            </span>

            <!-- Column: balance day -->
            <span v-else-if="props.column.field === 'balance_day'">
              {{ props.row.balance_day }}
              <div v-if="props.row.balance_day">
                <feather-icon icon="UserIcon" />
                <small>{{ props.row.user }}</small>
              </div>
            </span>

            <!-- Column: balance day -->
            <span v-else-if="props.column.field === 'quantity'">
              <div :class="props.row.up ? 'text-success' : ''">
                <span>{{ props.row.up }}</span>&nbsp;
                <feather-icon icon="ArrowUpIcon" />
              </div>
              <div :class="props.row.down ? 'text-danger' : ''">
                <span>{{ props.row.down ? `-${props.row.down}` : props.row.down }}</span>&nbsp;
                <feather-icon icon="ArrowDownIcon" />
              </div>
            </span>

            <span
              v-else-if="props.column.field === 'total'"
              style="max-width: 140px;"
            >{{ unitFormatOriginal(props.row.total) }}</span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pageOptions"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  // BTabs,
  // BTab
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BBadge,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import Treeselect from '@riophae/vue-treeselect'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BBadge,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    VueGoodTable,
    Treeselect,
    // flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      filterSelected: 0,
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      checkStatus: {
        s0: {
          value: 0,
          text: 'Đang kiểm kho',
          color: 'light-primary',
        },
        s1: {
          value: 1,
          text: 'Đã kiểm kho',
          color: 'light-info',
        },
        s2: {
          value: 2,
          text: 'Đã cân bằng',
          color: 'light-success',
        },
        s3: {
          value: 3,
          text: 'Đã huỷ',
          color: 'light-warning',
        },
      },
      model: {
        time: null,
        status: null,
        category_id: null,
      },
      supplierOptions: [],
      fields: [
        {
          field: 'code',
          label: 'Mã phiếu',
          width: '165px',
          sortable: false,
        },
        {
          field: 'warehouse',
          label: 'Warehouse',
          tdClass: '',
          sortable: false,
        },
        {
          field: 'status',
          label: 'Status',
          // tdClass: 'w-130',
          sortable: false,
        },
        {
          field: 'created_at',
          label: 'Created at',
          // tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'balance_day',
          label: 'Ngày cân bằng',
          // tdClass: 'text-end',
          // thClass: 'custom-th-class',
          sortable: false,
        },
        {
          field: 'quantity',
          label: 'SL lệch',
          type: 'number',
          tdClass: 'text-end',
          thClass: 'custom-th-class',
          sortable: false,
        },
        {
          field: 'total',
          label: 'Tổng lệch',
          type: 'number',
          tdClass: 'text-end',
          thClass: 'custom-th-class',
          sortable: false,
        },
      ],
      items: [
        {
          code: 'IR0707220330',
          created_at: '07/07/2022 15:30',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          total: 0,
          balance_day: '',
          status: 0,
          user: 'Huân Phan',
          up: 0,
          down: 0,
        },
        {
          code: 'IR0707220332',
          created_at: '07/07/2022 15:32',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          total: 87000000,
          balance_day: '07/07/2022 15:30',
          status: 1,
          user: 'Huân Phan',
          up: 2,
          down: 0,
        },
        {
          code: 'IR0707220332',
          created_at: '07/07/2022 15:32',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          total: 87000000,
          balance_day: '07/07/2022 15:30',
          status: 2,
          user: 'Huân Phan',
          up: 2,
          down: 0,
        },
        {
          code: 'IR0707220332',
          created_at: '07/07/2022 15:32',
          supplier_name: 'B&T',
          warehouse: 'iFashion 51',
          total: 0,
          balance_day: '07/07/2022 15:30',
          status: 1,
          user: 'c_tran',
          up: 0,
          down: 0,
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0 : 'light-dark',
        1     : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 0) return this.items
      return this.items.filter(item => item.status === this.filterSelected)
    },
  },
  created() {
  },
  methods: {
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.from = dateStr.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.to = dateStr.split(' ')[2]
      }
    },
    // onDateChange(dateFrom, dateTo) {
    // },
    doCopy(text, position = 'top-center') {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        },
        {
          position,
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table.v2 .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    // text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }

  .custom-th-class {
    text-align: end;
  }

  .w-130 {
    width: 130px;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
